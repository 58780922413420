import AOS from "aos";
import 'aos/dist/aos.css';

export default () => {
    AOS.init({
        disable: () => {
            if ( window.innerWidth < 1200 ) {
                return true;
            }

            return false;
        },
        duration: 1200,
        once: true
    });

    // DEN Immobilien - AOS Gutenberg Integration

    document.querySelectorAll( '.wp-block-column' ).forEach( ( elm, key ) => {
        if ( key % 2 === 0 ) {
            elm.setAttribute( 'data-aos', 'fade-up-right' );
        } else {
            elm.setAttribute( 'data-aos', 'fade-up-left' );
        }
    } );


    document.querySelectorAll( 'p.is-style-subline,h1' ).forEach( ( elm, key ) => {
        elm.setAttribute( 'data-aos', 'zoom-in' );
    } );


    document.querySelectorAll( '.menu-landingpages-container ul' ).forEach( ( elm ) => {
        elm.setAttribute( 'data-aos', 'flip-up' );
    } );

    AOS.refreshHard();
};
